<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Account</span>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Account</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-autocomplete
                    dense
                    flat
                    outlined
                    background-color="white"
                    :items="accounts"
                    v-model="account"
                    append-icon="mdi-magnify"
                    return-object
                    item-text="accountName"
                    @change="onChangeAccount"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4">
                  <v-subheader>D/C</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-radio-group v-model="item.type" row>
                    <v-radio label="Debit" value="Debit"></v-radio>
                    <v-radio label="Credit" value="Credit"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Amount</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-text-field
                    outlined
                    flat
                    dense
                    background-color="white"
                    type="number"
                    v-model.number="item.amount"
                    :rules="[v => v >= 0 || 'Amount must more than equal 0']"
                    persistent-hint
                    :hint="formatPrice(item.amount)"
                    @change="onChangeAmount"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Currency</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <span>{{ item.currency }}</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Rate</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-text-field
                    outlined
                    flat
                    dense
                    background-color="white"
                    type="number"
                    v-model.number="item.rate"
                    :rules="[v => v >= 0 || 'Rate must more than equal 0']"
                    persistent-hint
                    :hint="formatPrice(item.rate)"
                    @change="onChangeRate"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Amount IDR</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-text-field
                    outlined
                    flat
                    dense
                    background-color="white"
                    type="number"
                    v-model.number="item.amountIDR"
                    :rules="[v => v >= 0 || 'AmountIDR must more than equal 0']"
                    persistent-hint
                    :hint="formatPrice(item.amountIDR)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Reference</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-text-field
                    outlined
                    flat
                    dense
                    background-color="white"
                    v-model="item.reference"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-row>
                <v-col cols="4" sm="4" md="4" lg="4" class="py-0">
                  <v-subheader>Remark</v-subheader>
                </v-col>
                <v-col cols="8" sm="8" md="8" lg="8" class="py-0">
                  <v-textarea
                    outlined
                    flat
                    background-color="white"
                    v-model.number="item.remark"
                    rows="3"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" class="mr-2">
          Close
        </v-btn>
        <v-btn color="red" @click="deleteItem(1)" class="mr-2" v-if="!item.deleted">
          Delete
        </v-btn>
        <v-btn color="success" @click="deleteItem(0)" class="mr-2" v-if="item.deleted">
          Undo
        </v-btn>
        <v-btn color="primary" @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-journal",
  props: {
    dialog: Boolean,
    item: Object,
    index: Number,
    accounts: Array,
  },
  computed: {
    setAccount() {
      return this.accounts.filter(x => x.accountNumber === this.item.accountNumber)[0];
    },
  },
  watch: {
    setAccount(val) {
      this.account = val;
    },
  },
  data() {
    return {
      valid: true,
      account: null,
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.$emit("save", this.item);
        this.$emit("close");
      }
    },
    close() {
      this.$emit("close");
    },
    deleteItem(deleted) {
      if (this.item.id) {
        if (deleted === 0) {
          this.item.deleted = false;
        } else {
          this.item.deleted = true;
        }
      }
      this.$emit("deleteItem", { index: this.index, item: this.item });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onChangeAccount(val) {
      this.item.accountId = val.accountId;
      this.item.accountNumber = val.accountNumber;
      this.item.accountName = val.accountName;
    },
    onChangeAmount(val) {
      this.item.amountIDR = val * this.item.rate;
    },
    onChangeRate(val) {
      this.item.amountIDR = val * this.item.amount;
    },
  },
};
</script>

<style></style>
